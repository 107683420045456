import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { store } from './store';
import './registerServiceWorker'
import  VueGoogleMaps from '@fawmi/vue-google-maps'

caches.keys().then((keys) => {
  keys.forEach((key) => {
    caches.delete(key);
  });
});

createApp(App)
  .use(router)
  .use(store)
  .use(VueGoogleMaps, {
      load: {
          // key: 'AIzaSyBmybX9xvDDFWO6sC0b-N0pKSoOCizsZHY',
          // key: 'AIzaSyDe1nOgWHPEDT5dDPBqbbroRTO9o5pBA0U',
          key: 'AIzaSyA8o_9wtHGXYzEm7oYxrNcWwGFx5xhY8DM',
          libraries: 'places',
      },
  })
  .mount('#app')
